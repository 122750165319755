// Dependencies imports
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Local imports
import Logo from "../../assets/images/logo/avairsense.webp";
import LinkedinIcon from "../../assets/images/footer/linkedin.webp";
import TwitterIcon from "../../assets/images/footer/twitter.webp";
import FacebookIcon from "../../assets/images/footer/facebook.webp";
import InstagramIcon from "../../assets/images/footer/instagram.webp";

// Style imports
import Fo from "./Footer.module.scss";

const Footer = () => {
  const submiturl =
    "https://avairsense.us21.list-manage.com/subscribe/post?u=f9b705ecd04d0d9786d2e8604&amp;id=b30128d187&amp;f_id=006fcae1f0";
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [isEmailFormValid, setIsEmailFormValid] = useState(false);

  return (
    <div className={Fo.mainholder}>
      <div className={Fo.topsection}>
        <div className={Fo.logoholder}>
          <img className={Fo.avairsenseicon} src={Logo} alt="avairsenselogo" />
          <div className={Fo.socialshareicons}>
            <a
              href="https://www.linkedin.com/company/oureye-ai/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={LinkedinIcon}
                alt="socialicon"
                className={Fo.socialicons}
              />
            </a>
            <a
              href="https://www.instagram.com/avair.sense/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="socialicon"
                className={Fo.socialicons}
              />
            </a>
            <a
              href="https://twitter.com/AvairSense"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={TwitterIcon}
                alt="socialicon"
                className={Fo.socialicons}
              />
            </a>
            <a
              href="https://m.facebook.com/BringingCamerasToLife/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={FacebookIcon}
                alt="socialicon"
                className={Fo.socialicons}
              />
            </a>
          </div>
        </div>
        <div className={Fo.contactholder}>
          <h2 className={Fo.footerheadone}>Let's Talk Business</h2>
          <h3 className={Fo.footeremail}>support@avairsense.com</h3>
          <h4 className={Fo.footeraddress}>
            #28, 16th Main Road, 38th Cross,
            <br />
            Jayanagar 4th T block, Banglore,
            <br />
            Karnataka-560041
          </h4>
          <p className={Fo.footercontact}>+91-9148248898</p>
        </div>
        <div className={Fo.emailholder}>
          <form
            action={submiturl}
            className={Fo.emailform}
            method="post"
            target="_blank"
            noValidate
          >
            <input
              className={Fo.emailinput}
              placeholder="Enter email to receive updates first"
              type="text"
              value={email}
              name="EMAIL"
              onChange={(e) => {
                setEmailErr("");
                setEmail(e.target.value);
                if (
                  e.target.value === "" ||
                  e.target.value === null ||
                  e.target.value.trim() === ""
                ) {
                  setEmailErr("Email cannot be empty");
                  setIsEmailFormValid(false);
                  return;
                }
                if (
                  !e.target.value.match(
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                  )
                ) {
                  setEmailErr("Incorrect email format");
                  setIsEmailFormValid(false);
                  return;
                }
                setIsEmailFormValid(true);
              }}
            />
            <h3 className={Fo.error}>{emailErr}</h3>
            <button
              className={
                isEmailFormValid && email !== ""
                  ? Fo.gradientButton
                  : Fo.gradientButtonDisabled
              }
              type="submit"
            >
              Stay in touch
            </button>
          </form>
        </div>
      </div>
      <div className={Fo.bottomsection}>
        <h4 className={Fo.footertext}>
          Copyright 2022 OurEye Pvt Ltd. All rights reserved |{" "}
          <Link to="/termsandconditions" className={Fo.footlink}>
            Terms of use
          </Link>
        </h4>
        <h4 className={Fo.footertext}>
          <Link to="/support" className={Fo.footlink}>
            Support
          </Link>{" "}
          |{" "}
          <Link to="/refundpolicy" className={Fo.footlink}>
            Refund Policy
          </Link>{" "}
          |{" "}
          <Link to="/privacypolicy" className={Fo.footlink}>
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link to="/shippinganddelivery" className={Fo.footlink}>
            Shipping & Delivery
          </Link>
        </h4>
      </div>
    </div>
  );
};

export default Footer;
