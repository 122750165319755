import { useState, useEffect } from "react";
import YouTube from "react-youtube";

import { isMobile } from "react-device-detect";

import { ReactComponent as NavigateNext } from "../../assets/icons/navigate_next.svg";
import { ReactComponent as NavigateBefore } from "../../assets/icons/navigate_before.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import Yo from "./YoutubePlayerModal.module.scss";

const YoutubePlayerModal = (props) => {
  const { setOpenYoutubeModal, videoId, currentVideoId, setCurrentVideoId } =
    props;

  useEffect(() => {}, [currentVideoId]);

  const opts = {
    height: isMobile ? "240" : "360",
    width: isMobile ? "380" : "600",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      VideoPlaybackQuality: "1080p",
    },
  };

  const increaseVideoId = () => {
    if (currentVideoId === videoId.length - 1) {
      setCurrentVideoId(0);
    } else {
      setCurrentVideoId(currentVideoId + 1);
    }
  };

  const decreaseVideoId = () => {
    if (currentVideoId === 0) {
      setCurrentVideoId(videoId.length - 1);
    } else {
      setCurrentVideoId(currentVideoId - 1);
    }
  };

  return (
    <div className={Yo.maincontainer}>
      <CloseIcon
        className={Yo.closeicon}
        onClick={() => setOpenYoutubeModal(false)}
      />
      <NavigateBefore
        className={Yo.navigatebeforeicon}
        onClick={() => decreaseVideoId()}
      />
      <NavigateNext
        className={Yo.navigateaftericon}
        onClick={() => increaseVideoId()}
      />
      <YouTube
        videoId={videoId[currentVideoId]}
        opts={opts}
        onEnd={() => increaseVideoId()}
      />
    </div>
  );
};

export default YoutubePlayerModal;
