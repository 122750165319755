// Dependencies imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import qs from "qs";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import { offeredCustomer } from "../../recoil/atoms/offeredCustomer";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// Style imports
import Of from "./Offers.module.scss";

const Offers = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [hear, setHear] = useState("");
  const [locality, setLocality] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [hearErr, setHearErr] = useState("");
  const [localityErr, setLocalityErr] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //eslint-disable-next-line
  const [customerOffer, setCustomerOffer] = useRecoilState(offeredCustomer);

  const handleSubmit = (e) => {
    let isFormValid = true;
    setNameErr("");
    setEmailErr("");
    setPhoneErr("");
    setHearErr("");
    setLocalityErr("");

    if (name === "" || name === null || name.trim() === "") {
      isFormValid = false;
      setNameErr("Name cannot be empty");
      return;
    }
    if (
      phoneNumber === "" ||
      phoneNumber === null ||
      phoneNumber.trim() === ""
    ) {
      setPhoneErr("Phone number cannot be empty");
      isFormValid = false;
      return;
    }
    if (phoneNumber.length !== 10) {
      setPhoneErr("Phone number should contain 10 digits");
      isFormValid = false;
      return;
    }
    if (/^\d+$/.test(phoneNumber) === false) {
      setPhoneErr("Phone number should contain only numbers");
      isFormValid = false;
      return;
    }
    if (email === "" || email === null || email.trim() === "") {
      setEmailErr("Email cannot be empty");
      isFormValid = false;
      return;
    }
    if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailErr("Incorrect email format");
      isFormValid = false;
      return;
    }
    if (hear === "" || hear === null || hear.trim() === "") {
      setHearErr("Please select an option");
      isFormValid = false;
      return;
    }
    if (locality === "" || locality === null || locality.trim() === "") {
      setLocalityErr("Please select your apartment");
      isFormValid = false;
      return;
    }
    if (isFormValid) {
      getCouponCode();
    }
  };

  //API to issue kits
  const getCouponCode = () => {
    setLoading(true);
    let data = {
      name: name.toString(),
      contact: phoneNumber.toString(),
      email: email.toString(),
      mode: hear.toString(),
      locality: locality.toString(),
    };

    var config = {
      method: "POST",
      url: `https://api.dev.avairsense.com/coupon/add/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        setLoading(false);
        let datatoset = {
          name: name,
          contact: phoneNumber,
          email: email,
          headof: hear,
          apartment: locality,
          coupon: result.data.coupon_code,
        };
        setCustomerOffer(datatoset);
        navigate("/couponcode");
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div className={Of.maincontainer}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <div className={Of.firstsection}>
        <h1 className={Of.heading}>Sense 1 Discount Coupon</h1>
        <p className={Of.intro}>
          Please fill out the following information to receive the coupon code.
        </p>
        <div className={Of.inputdetailsholders}>
          <div className={Of.inputholder}>
            <label className={Of.label}>Full Name</label>
            <input
              value={name}
              type="text"
              name="NAME"
              className={Of.inputfield}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <p className={Of.formerror}>{nameErr}</p>
          <div className={Of.inputholder}>
            <label className={Of.label}>Phone Number</label>
            <input
              value={phoneNumber}
              type="tel"
              name="PHONE"
              className={Of.inputfield}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </div>
          <p className={Of.formerror}>{phoneErr}</p>
          <div className={Of.inputholder}>
            <label className={Of.label}>Email</label>
            <input
              type="email"
              className={Of.inputfield}
              name="EMAIL"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <p className={Of.formerror}>{emailErr}</p>
          <div className={Of.inputholder}>
            <label className={Of.label}>How did you hear about us?</label>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="googlesearch"
                name="HEARD"
                value="googlesearch"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "googlesearch" ? true : false}
              />
              <label for="googlesearch" className={Of.radiolabel}>
                Google Search
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="marketing"
                name="HEARD"
                value="marketing"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "marketing" ? true : false}
              />
              <label for="marketing" className={Of.radiolabel}>
                Marketing Pamphlet
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="onlinead"
                name="HEARD"
                value="online"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "online" ? true : false}
              />
              <label for="onlinead" className={Of.radiolabel}>
                Online Ad
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="friends"
                name="HEARD"
                value="friends"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "friends" ? true : false}
              />
              <label for="friends" className={Of.radiolabel}>
                Friends or Family
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="experience centers"
                name="HEARD"
                value="experience centers"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "experience centers" ? true : false}
              />
              <label for="experience centers" className={Of.radiolabel}>
                Experience Centers
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="socialmedia"
                name="HEARD"
                value="socialmedia"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "socialmedia" ? true : false}
              />
              <label for="socialmedia" className={Of.radiolabel}>
                Social Media
              </label>
            </div>
            <div className={Of.radioholders}>
              <input
                type="radio"
                id="other"
                value="other"
                name="HEARD"
                onChange={(e) => {
                  setHear(e.target.value);
                }}
                checked={hear === "other" ? true : false}
              />
              <label for="other" className={Of.radiolabel}>
                Other
              </label>
            </div>
          </div>
          <p className={Of.formerror}>{hearErr}</p>
          <div className={Of.inputholder}>
            <label className={Of.label}>Which locality do you reside in?</label>
            <select
              value={locality}
              type="text"
              name="LOCALITY"
              className={Of.selectfield}
              onChange={(e) => {
                setLocality(e.target.value);
              }}
            >
              <option selected="true">Select Your Apartment</option>
              <option value="HCM">Hiranandani Club Meadows</option>
              <option value="EP">Embassy Pristine</option>
              <option value="DNRR">DNR Reflection</option>
              <option value="PFS">Purva Fountain Square</option>
              <option value="SC">Salarpuria Cadenza</option>
              <option value="ME">Mantri Elite</option>
              <option value="Mantri">Mantri</option>
              <option value="Prestige Song of the South">
                Prestige Song of the South
              </option>
              <option value="DLF Westend">DLF Westend</option>
              <option value="Phoenix One">Phoenix One</option>
            </select>
          </div>
          <p className={Of.formerror}>{localityErr}</p>
          <button
            className={Of.submitbutton}
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Offers;
