import React, { useRef } from "react";
import ScratchCard from "react-scratchcard-v2";

import { useRecoilState } from "recoil";
import { offeredCustomer } from "../../recoil/atoms/offeredCustomer";
import ScratchImage from "../../assets/images/offers/frame.webp";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Header from "../../components/header/Header";

import Sc from "./ScratchCard.module.scss";

const ScratchCardPage = () => {
  //eslint-disable-next-line
  const [customerOffer, setCustomerOffer] = useRecoilState(offeredCustomer);

  const ref = useRef < ScratchCard > null;

  const onClickReset = () => {
    ref.current && ref.current.reset();
  };

  return (
    <div className={Sc.maincontainer}>
      <Header />
      <div className={Sc.contentholder}>
        <h1 className={Sc.namehead}>Hi {customerOffer.name}</h1>
        <h3 className={Sc.mainpara}>
          Thank you for showing interest in unlocking peace and keeping your
          loved one's safe! ✨
        </h3>
        <ScratchCard
          width={200}
          height={200}
          className={Sc.scratchcard}
          image={ScratchImage}
          finishPercent={90}
          style={{ marginTop: "5vh" }}
        >
          <div className={Sc.cardholder}>
            <h1 className={Sc.codestyle}>{customerOffer.coupon}</h1>
          </div>
        </ScratchCard>
        <h3 className={Sc.mainpara}>
          Welcome home India's smartest camera ever now.
        </h3>
        <CopyToClipboard text={customerOffer.coupon}>
          <a
            onClick={() => {
              navigator.clipboard.writeText();
            }}
            href="https://shop.avairsense.com"
          >
            <button className={Sc.copybutton}>Copy & Go to Shop</button>
          </a>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ScratchCardPage;
