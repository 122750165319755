// Dependencies imports
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import YouTube from "react-youtube";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import YCombinatorLogo from "../../assets/images/home/ycomblogo.webp";
import { useRecoilState } from "recoil";

// Local imports
import Adone from "../../assets/images/home/adone.png";
import Adtwo from "../../assets/images/home/adtwo.png";
import Adthree from "../../assets/images/home/adthree.png";
import Adfour from "../../assets/images/home/adfour.png";
import Adfive from "../../assets/images/home/adfive.png";
import Adsix from "../../assets/images/home/adsix.png";
import Header from "../../components/header/Header";
import Camera from "../../assets/images/home/cameratwo.webp";
import CameraTwo from "../../assets/images/home/camera.webp";
import BuyOnAmazon from "../../assets/images/home/amazon.webp";
import LeftArrow from "../../assets/icons/home/leftarrow.svg";
import RightArrow from "../../assets/icons/home/rightarrow.svg";
import WhatsAppIntegrationIcon from "../../assets/images/home/whatsapp.webp";
import FaceRecognitionIcon from "../../assets/images/home/facerecognition.webp";
import PetDetectionIcon from "../../assets/icons/home/petdetection.svg";
import EmergencySOSIcon from "../../assets/images/home/emergencysos.webp";
import BatteryIcon from "../../assets/icons/home/battery.svg";
import ResolutionIcon from "../../assets/icons/home/fhdresolution.svg";
import NightVisionIcon from "../../assets/icons/home/nightvision.svg";
import IndoorAndOutdoorIcon from "../../assets/icons/home/indoorandoutdoor.svg";
import SmartNotificationIcon from "../../assets/icons/home/notificaiton.svg";
import TwoWayAudioIcon from "../../assets/icons/home/2-way-audio.svg";
import FaceDetectionAlert from "../../assets/images/home/facedetection.webp";
import IntrusionAlert from "../../assets/images/home/intrusionalerts.webp";
import MotionAlert from "../../assets/images/home/motionalerts.webp";
import PetActivityAlert from "../../assets/images/home/petactivity.webp";
import VehicleDetectionAlert from "../../assets/images/home/vehicledetection.webp";
import PetIcon from "../../assets/icons/peticon.svg";
import OfficeCamMobile from "../../assets/images/home/officecam.webp";
import WhatsAppAlertMobile from "../../assets/images/home/whatsappalert.webp";
import WhatsAppIcon from "../../assets/images/home/whatsapp.webp";
import EmergencyContactMobile from "../../assets/images/home/emergencycontact.webp";
import FaceRecIcon from "../../assets/icons/facerecicon.svg";
import IntrusionIcon from "../../assets/icons/intrusionicon.svg";
import VehicleDetectionIcon from "../../assets/icons/vehicledetectionicon.svg";
import MotionAlertIcon from "../../assets/icons/motionalerticon.svg";
import GooglePlay from "../../assets/images/home/googleplay.webp";
import AppStore from "../../assets/images/home/appstore.webp";
import SubscriptionImage from "../../assets/images/home/subscription.webp";
import MobileSubscriptionImage from "../../assets/images/home/mobilesubscription.webp";
import { offerModalState } from "../../recoil/atoms/offerModalState";
import YoutubePlayerModal from "../../components/youtubePlayerModal/YoutubePlayerModal";
import SpecialDiscountModal from "../../components/offersModal/OffersModal";
import HomeProtectionImage from "../../assets/images/home/homeprotection.webp";
import ElderlyProtectionImage from "../../assets/images/home/elderly.webp";
import BabyMonitoringImage from "../../assets/images/home/babymonitoring.webp";
import PetcamImage from "../../assets/images/home/petcam.webp";
import Footer from "../../components/footer/Footer";

// Style imports
import Ho from "./NewHome.module.scss";

const features = [
  {
    icon: EmergencySOSIcon,
    heading: "Emergency SOS",
    content:
      "Add emergency contacts who will be notified immediately whenever as SOS signal is triggered, providing an extra layer of safety and support.",
  },
  {
    icon: FaceRecognitionIcon,
    heading: "Face Recognition",
    content:
      "Experience personalized security with our advanced face recognition feature. Get custom alerts whenever familiar faces are detected, ensuring peace of mind and enhanced home protection.",
  },
  {
    icon: PetDetectionIcon,
    heading: "Pet Detection",
    content:
      "Keep your beloved pet safe and monitored with our innovative pet detection feature. Track their activties and receive real-time updated, ensuring their well-being even when you're away from home or the office.",
  },
  {
    icon: WhatsAppIcon,
    heading: "WhatsApp Alerts",
    content:
      "Stay connected effortlessly with our WhatsApp notifications feature. Receive instant alerts and updates directly to your WhatsApp, ensuring you never miss important events or security notifications.",
  },
];

const protectFeatures = [
  {
    icon: HomeProtectionImage,
    heading: "Home Protection",
    content:
      "Receive instant alerts and take action against unauthorized access, ensuring peace of mind.",
  },
  {
    icon: BabyMonitoringImage,
    heading: "Baby Monitoring",
    content:
      "Monitor their safety, listen to sounds, and receive alerts, providing reassurance and peace.",
  },
  {
    icon: PetcamImage,
    heading: "Pet Cam",
    content:
      "Never lose sight of your furry friend. Track their activity & receive real-time updates.",
  },
  {
    icon: ElderlyProtectionImage,
    heading: "Elderly Protection",
    content:
      "Ensure the well-being of your loved ones with our comprehensive elderly care solution.",
  },
];

const hardwareFeatures = [
  {
    icon: BatteryIcon,
    heading: "Rechargeable Battery",
    content: "Go Wireless with a 5200mAh battery for ease of use.",
    type: "odd",
  },
  {
    icon: ResolutionIcon,
    heading: "FHD Resolution",
    content: "Get FHD playback up to 1080p with out 3MP camera.",
    type: "even",
  },
  {
    icon: NightVisionIcon,
    heading: "Auto Night Vision",
    content:
      "Keep an eye on surroundings even in low light with IR Night Vision.",
    type: "odd",
  },
  {
    icon: IndoorAndOutdoorIcon,
    heading: "Indoor & Outdoor",
    content:
      "Rain, Snow, or Sun. Sense 1 will continue to protect in all weather conditions.",
    type: "even",
  },
  {
    icon: SmartNotificationIcon,
    heading: "Smart Notifications",
    content: "Get custom notifications at your fingertips on the beAvair app.",
    type: "odd",
  },
  {
    icon: TwoWayAudioIcon,
    heading: "2-way Audio",
    content:
      "Talk to your door from anywhere using the built-in mic and speaker",
    type: "even",
  },
];

const HomeTwo = () => {
  const [activeTab, setActiveTab] = useState("pet_activity");
  const [expanded, setExpanded] = useState(false);
  const [question, setQuestion] = useState("");
  const [email, setEmail] = useState("");
  const [questionErr, setQuestionErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [openYoutubeModal, setOpenYoutubeModal] = useState(false);
  const [videoId, setVideoId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [isModalOpened, setIsModalOpened] = useRecoilState(offerModalState);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [ytvideoId, setYtVideoId] = useState("");

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 860) {
      if (!isModalOpened) {
        handleOpenOfferModal();
      }
    }
    //eslint-disable-next-line
  }, [scrollPosition]);

  useEffect(() => {
    getAllResources();

    //eslint-disable-next-line
  }, []);

  const getAllResources = () => {
    setLoading(true);

    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_RESOURCE_ENDPOINT}/api/resources?populate=*`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setVideoData(result.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const setVideoData = (val) => {
    let videoIds = [];
    let thumbnails = [];
    for (let i = 0; i < val.length; i++) {
      videoIds.push(val[i].attributes.YoutubeLink);
      thumbnails.push(val[i].attributes.verticalthumbnail.data.attributes.url);
    }
    setVideoId(videoIds);
    setThumbnails(thumbnails);
  };

  const submiturl =
    "https://avairsense.us21.list-manage.com/subscribe/post?u=f9b705ecd04d0d9786d2e8604&amp;id=b30128d187&amp;f_id=006fcae1f0";

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenOfferModal = () => {
    setOpenOfferModal(true);
    setIsModalOpened(true);
  };

  const handleCloseOfferModal = () => {
    setOpenOfferModal(false);
  };

  const opts = {
    height: isMobile ? "240" : "460",
    width: isMobile ? "380" : "800",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      VideoPlaybackQuality: "1080p",
    },
  };

  return (
    <div className={Ho.maincontainer}>
      <Helmet>
        <title>AvairSense | Smart AI Camera | Sense 1</title>
        <meta
          name="description"
          content="AvairSense builds AI cameras for every industry. Sense 1 is our top-of-the-line home security camera, featuring a built-in battery, motion detection, two-way audio, and cloud storage. With advanced AI technology, Sense 1 can detect persons, pets, vehicles, and more. Secure your home with Sense 1, order yours now."
        />
        <meta
          name="keywords"
          content="best AI camera in India, best cloud based CCTV camera, wireless CCTV installation, indoor security camera for home,face recognition security camera, face detection CCTV for home, AI security camera, CCTV with cloud storage, best outdoor CCTV camera, security camera with privacy modes,night vision camera,AI camera on edge,AI VMS software,security camera for home,alarm system online,4G CCTV in India,Top 5 security cameras in India,Top security system under 10\,000,AvairSense, Sense 1,Avair Sense "
        />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openModal}
        onClick={() => {
          setOpenModal(false);
          setYtVideoId("");
        }}
      >
        <YouTube
          videoId={ytvideoId}
          opts={opts}
          onEnd={() => setOpenModal(false)}
        />
      </Backdrop>
      <Modal
        open={openOfferModal}
        onClose={handleCloseOfferModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SpecialDiscountModal handleCloseOfferModal={handleCloseOfferModal} />
      </Modal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <div className={Ho.sectionone}>
        <div className={Ho.leftsectionone}>
          <h2 className={Ho.senseheading}>Sense 1</h2>
          <h3 className={Ho.intropara}>
            <span className={Ho.gradienttext}>Unlock Peace</span> with India's
            smartest camera ever for{" "}
            <span className={Ho.gradienttext}>Ultimate homes.</span>
          </h3>
          <div className={Ho.ctaholders}>
            <a href="https://shop.avairsense.com/">
              <button className={Ho.buynow}>Buy Now!</button>
            </a>
            <a
              href="https://www.amazon.in/dp/B0C53WDKKB?ref=myi_title_dp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={Ho.buyonamazon}
                src={BuyOnAmazon}
                alt="buy on amazon"
              />
            </a>
          </div>
        </div>
        <div className={Ho.rightsectione}>
          <img className={Ho.cameraimage} alt="cameraimage" src={Camera} />
          <div className={Ho.ctaholdersmobile}>
            <a href="https://shop.avairsense.com/">
              <button className={Ho.buynow}>I want one!</button>
            </a>

            <a
              href="https://www.amazon.in/dp/B0C53WDKKB?ref=myi_title_dp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={Ho.buyonamazon}
                src={BuyOnAmazon}
                alt="buy on amazon"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={Ho.sectiontwo}>
        <h2 className={Ho.sectionheading}>Hear from our happy Customers</h2>
        <div className={Ho.reviewsliders}>
          <img
            src={LeftArrow}
            alt="leftarrow"
            className={Ho.navicon}
            id="leftArrow"
          />
          <div className={Ho.reviewsholder}>
            <div className={Ho.leftreview}>
              <div className={Ho.topratingholder}>
                <Rating
                  name="read-only"
                  value={5}
                  readOnly
                  size="small"
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <h3 className={Ho.username}>Fauzan Khan</h3>
              </div>
              <h4 className={Ho.review}>
                The camera is very cute and I love the app explaining all the
                privacy features and how it keeps my data secure. Very premium
                build quality. And the app is custom made for the camera.
              </h4>
            </div>
            <div className={Ho.middlereview}>
              <div className={Ho.topratingholder}>
                <Rating
                  name="read-only"
                  value={4}
                  readOnly
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.8, color: "#fff" }}
                      fontSize="inherit"
                    />
                  }
                />
                <h3 className={Ho.usernamemiddle}>Somanth M</h3>
              </div>
              <h4 className={Ho.reviewmiddle}>
                Liked the product, thanks AvairSense for the fast delivery and
                support over calls. I have used it as outdoor camera to keep an
                eye on people outside the door.
              </h4>
            </div>
            <div className={Ho.rightreview}>
              <div className={Ho.topratingholder}>
                <Rating
                  name="read-only"
                  value={5}
                  readOnly
                  size="small"
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
                <h3 className={Ho.username}>Rohit Kumar</h3>
              </div>
              <h4 className={Ho.review}>
                Impressed by the build quality and premium app. Very smooth.
                Also was very surprised on the WhatsApp notification no other
                camera having that feature.
              </h4>
            </div>
          </div>
          <img
            src={RightArrow}
            alt="rightarrow"
            className={Ho.navicon}
            id="rightArrow"
          />
        </div>
        <div className={Ho.reviewdotsholder}>
          <div className={Ho.dotactive}></div>
          <div className={Ho.dot}></div>
          <div className={Ho.dot}></div>
        </div>
      </div>
      <div className={Ho.protectsection}>
        <h3 className={Ho.gradientprotectheading}>Protect What You Love</h3>
        <div className={Ho.protectholders}>
          {protectFeatures.map((value, i) => {
            return (
              <div className={Ho.protectindividual}>
                <img
                  className={Ho.protectimage}
                  src={value.icon}
                  alt="protectimage"
                />
                <h3 className={Ho.protectheading}>{value.heading}</h3>
                <p className={Ho.protectinfo}>{value.content}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Ho.sectionfive}>
        <h3 className={Ho.gradientheading}>Only home security camera</h3>
        <h4 className={Ho.sectionheading}>You will ever need</h4>
        <div className={Ho.hardwarefeature}>
          {hardwareFeatures.map((value, index) => {
            return (
              <div
                key={value.heading}
                className={
                  value.type === "even"
                    ? Ho.hardwarefeatureholderlight
                    : Ho.hardwarefeatureholder
                }
              >
                <img
                  className={Ho.hardwarefeatureicon}
                  alt={value.heading}
                  src={value.icon}
                />
                <h3 className={Ho.hardwarefeatureheading}>{value.heading}</h3>
                <p className={Ho.hardwarefeaturecontent}>{value.content}</p>
              </div>
            );
          })}
          <div className={Ho.individualhardwarefeature}></div>
        </div>
        <Link to="/features">
          <button className={Ho.tellmemore}>Tell me more!</button>
        </Link>
      </div>

      <div className={Ho.sectionfour}>
        <h2 className={Ho.avairsensetext}>AvairSense.</h2>
        <div className={Ho.thefourlineholder}>
          <p className={Ho.firstpara}>We go all out</p>
          <p className={Ho.firstpara}>
            To build the <span className={Ho.gradientspan}>smartest</span>{" "}
            camera ever.
          </p>
          <p className={Ho.firstpara}>
            To help you <span className={Ho.gradientspan}>see beyond</span> what
            you can.
          </p>
          <p className={Ho.firstpara}>
            To empower you to <span className={Ho.gradientspan}>protect</span>{" "}
            all that you love.
          </p>
        </div>
        <img className={Ho.cameratwoimage} src={CameraTwo} alt="cameraimage" />
      </div>
      <div className={Ho.sectionthree}>
        <h3 className={Ho.gradientheading}>
          Don't settle for ordinary cameras
        </h3>
        <h4 className={Ho.sectionheading} style={{ padding: "0 5vw" }}>
          Get your hands on the next gen AI features.
        </h4>
        {/* <div className={Ho.featuresholder}>
          {features.map((value, index) => {
            return (
              <div className={Ho.individualfeature} key={value.heading}>
                <img
                  className={Ho.newfeatureicon}
                  src={value.icon}
                  alt={value.heading}
                />

                <div className={Ho.hrline}></div>
                <h3 className={Ho.newfeatureheading}>{value.heading}</h3>
                <p className={Ho.newfeaturecontent}>{value.content}</p>
              </div>
            );
          })}
        </div>
        <Link to="/features">
          <button className={Ho.tellmemore}>Tell me more!</button>
          </Link> */}
        <div className={Ho.tricoloursections}>
          <div className={Ho.bluesectionholder}>
            <div className={Ho.mobileimageholder}>
              <img
                src={EmergencyContactMobile}
                className={Ho.emergencyimage}
                alt="mobile"
              />
            </div>
            <div className={Ho.individualfeature}>
              <img
                className={Ho.newfeatureicon}
                src={features[0].icon}
                alt={features[0].heading}
              />
              <div className={Ho.hrline}></div>
              <h3 className={Ho.newfeatureheading}>{features[0].heading}</h3>
              <p className={Ho.newfeaturecontent}>{features[0].content}</p>
            </div>
          </div>
          <div
            className={Ho.bluesectionholder}
            style={{ background: "#121212" }}
          >
            <div className={Ho.individualfeature}>
              <img
                className={Ho.newfeatureicon}
                src={features[1].icon}
                alt={features[1].heading}
              />
              <div className={Ho.hrline}></div>
              <h3 className={Ho.newfeatureheading}>{features[1].heading}</h3>
              <p className={Ho.newfeaturecontent}>{features[1].content}</p>
            </div>
            <div className={Ho.mobileimageholder}>
              <img
                src={OfficeCamMobile}
                className={Ho.emergencyimage}
                alt="mobile"
              />
            </div>
          </div>
          <div
            className={Ho.bluesectionholder}
            style={{ background: "#F2E361" }}
          >
            <div className={Ho.mobileimageholder}>
              <img
                src={WhatsAppAlertMobile}
                className={Ho.emergencyimage}
                alt="mobile"
              />
            </div>
            <div className={Ho.individualfeature}>
              <img
                className={Ho.newfeatureicon}
                src={features[3].icon}
                alt={features[3].heading}
              />
              <div className={Ho.hrline}></div>
              <h3 className={Ho.newfeatureheading} style={{ color: "#121212" }}>
                {features[3].heading}
              </h3>
              <p className={Ho.newfeaturecontent} style={{ color: "#3C3C3C" }}>
                {features[3].content}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={Ho.sectionsix}>
        <h2 className={Ho.sectionheading}>
          <span className={Ho.gradienttext}>Intelligent Alerts,</span> at your
          Fingertips
        </h2>
        <div className={Ho.actionholder}>
          <div className={Ho.rightimageholder}>
            <img
              className={Ho.alertimage}
              src={
                activeTab === "pet_activity"
                  ? PetActivityAlert
                  : activeTab === "face_recognition"
                  ? FaceDetectionAlert
                  : activeTab === "vehicle_detection"
                  ? VehicleDetectionAlert
                  : activeTab === "motion_alerts"
                  ? MotionAlert
                  : activeTab === "intrusion_detection"
                  ? IntrusionAlert
                  : null
              }
              alt="dynamic-island"
            />
            <div className={Ho.downloadiconholder}>
              <a
                href="https://play.google.com/store/apps/details?id=com.avairsense.beavair&hl=en-IN"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={GooglePlay}
                  alt="googleplay"
                  className={Ho.downloadicon}
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/beavair/id6449227841"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStore}
                  alt="appstore"
                  className={Ho.downloadicon}
                />
              </a>
            </div>
          </div>
          <div className={Ho.leftsideoptions}>
            <div
              className={Ho.featureholder}
              onClick={() => setActiveTab("pet_activity")}
            >
              <div
                className={
                  activeTab === "pet_activity"
                    ? Ho.featureiconholder_active
                    : Ho.featureiconholder
                }
              >
                <img className={Ho.featureicon} src={PetIcon} alt="peticon" />
                <h4
                  className={
                    activeTab === "pet_activity"
                      ? Ho.featureheading_active
                      : Ho.featureheading
                  }
                >
                  Pet Activity
                </h4>
              </div>
            </div>
            <div
              className={Ho.featureholder}
              onClick={() => setActiveTab("face_recognition")}
            >
              <div
                className={
                  activeTab === "face_recognition"
                    ? Ho.featureiconholder_active
                    : Ho.featureiconholder
                }
              >
                <img
                  className={Ho.featureicon}
                  src={FaceRecIcon}
                  alt="peticon"
                />
                <h4
                  className={
                    activeTab === "face_recognition"
                      ? Ho.featureheading_active
                      : Ho.featureheading
                  }
                >
                  Face Recognition
                </h4>
              </div>
            </div>
            <div
              className={Ho.featureholder}
              onClick={() => setActiveTab("vehicle_detection")}
            >
              <div
                className={
                  activeTab === "vehicle_detection"
                    ? Ho.featureiconholder_active
                    : Ho.featureiconholder
                }
              >
                <img
                  className={Ho.featureicon}
                  src={VehicleDetectionIcon}
                  alt="peticon"
                />
                <h4
                  className={
                    activeTab === "vehicle_detection"
                      ? Ho.featureheading_active
                      : Ho.featureheading
                  }
                >
                  Vehicle Detected
                </h4>
              </div>
            </div>
            <div
              className={Ho.featureholder}
              onClick={() => setActiveTab("motion_alerts")}
            >
              <div
                className={
                  activeTab === "motion_alerts"
                    ? Ho.featureiconholder_active
                    : Ho.featureiconholder
                }
              >
                <img
                  className={Ho.featureicon}
                  src={MotionAlertIcon}
                  alt="peticon"
                />
                <h4
                  className={
                    activeTab === "motion_alerts"
                      ? Ho.featureheading_active
                      : Ho.featureheading
                  }
                >
                  Motion Alerts
                </h4>
              </div>
            </div>
            <div
              className={Ho.featureholder}
              onClick={() => setActiveTab("intrusion_detection")}
            >
              <div
                className={
                  activeTab === "intrusion_detection"
                    ? Ho.featureiconholder_active
                    : Ho.featureiconholder
                }
              >
                <img
                  className={Ho.featureicon}
                  src={IntrusionIcon}
                  alt="peticon"
                />{" "}
                <h4
                  className={
                    activeTab === "intrusion_detection"
                      ? Ho.featureheading_active
                      : Ho.featureheading
                  }
                >
                  Intrusion Detected
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Ho.downloadiconholdermobile}>
        <h3 className={Ho.gradientheading}>Download the beAvair app!</h3>
        <a
          href="https://play.google.com/store/apps/details?id=com.avairsense.beavair&hl=en-IN"
          target="_blank"
          rel="noreferrer"
        >
          <img src={GooglePlay} alt="googleplay" className={Ho.downloadicon} />
        </a>
        <a
          href="https://apps.apple.com/in/app/beavair/id6449227841"
          target="_blank"
          rel="noreferrer"
        >
          <img src={AppStore} alt="appstore" className={Ho.downloadicon} />
        </a>
      </div>
      <div className={Ho.sectionseven}>
        <h3 className={Ho.sectionheading} style={{ marginBottom: "5vh" }}>
          Sense Subscriptions
        </h3>
        <img
          className={Ho.subimage}
          src={SubscriptionImage}
          alt="subscriptionimage"
        />
        <img
          className={Ho.subimagemobile}
          src={MobileSubscriptionImage}
          alt="mobilesubscription"
        />
        <div className={Ho.bluesection}>
          <h3 className={Ho.bluetext}>
            1 year Warranty on Hardware + 1year free Sense Nova Subscription
            worth ₹2988.
          </h3>
        </div>
      </div>
      <div className={Ho.tvcsection}>
        <h3 className={Ho.gradientheading}>TVC's</h3>
        <div className={Ho.tvcholders}>
          <img
            className={Ho.bigimage}
            src={Adone}
            alt="thumbnail"
            onClick={() => {
              setYtVideoId("iZ4reV1GPVU");
              setOpenModal(true);
            }}
          />
          <div className={Ho.twosmallimageholder}>
            <img
              className={Ho.twosmallimage}
              src={Adtwo}
              alt="thumbnail"
              onClick={() => {
                setYtVideoId("1exnEZm8l48");
                setOpenModal(true);
              }}
            />
            <img
              className={Ho.twosmallimage}
              src={Adthree}
              alt="thumbnail"
              onClick={() => {
                setYtVideoId("rz-9jyeG_3s");
                setOpenModal(true);
              }}
            />
          </div>
          <img className={Ho.bigimage} src={Adsix} alt="thumbnail" />
          <div className={Ho.twosmallimageholder}>
            <img className={Ho.twosmallimage} src={Adfour} alt="thumbnail" />
            <img className={Ho.twosmallimage} src={Adfive} alt="thumbnail" />
          </div>
        </div>
      </div>
      <div className={Ho.sectioneight}>
        <h3 className={Ho.sectionheading}>
          How to use Sense 1 and the beAvair app.
        </h3>
        <div className={Ho.thumbnailholders}>
          <div
            className={Ho.leftthumb}
            onClick={
              openYoutubeModal
                ? null
                : () => {
                    setCurrentVideoId(0);
                    setOpenYoutubeModal(true);
                  }
            }
            style={{ zIndex: openYoutubeModal ? -1 : 1000 }}
          >
            <img
              src={thumbnails[6]}
              alt="thumbone"
              style={{
                width: isMobile ? "20vw" : "220px",
                height: isMobile ? "160px" : "340px",
                borderRadius: "10px",
                opacity: openYoutubeModal ? "0.1" : "1",
              }}
            />
          </div>
          <div
            className={openYoutubeModal ? Ho.middlethumbactive : Ho.middlethumb}
            onClick={
              openYoutubeModal
                ? null
                : () => {
                    setCurrentVideoId(1);
                    setOpenYoutubeModal(true);
                  }
            }
          >
            {openYoutubeModal ? (
              <YoutubePlayerModal
                setOpenYoutubeModal={setOpenYoutubeModal}
                videoId={videoId}
                currentVideoId={currentVideoId}
                setCurrentVideoId={setCurrentVideoId}
              />
            ) : (
              <div>
                <img
                  src={thumbnails[7]}
                  alt="thumbone"
                  style={{
                    width: isMobile ? "35vw" : "220px",
                    height: isMobile ? "240px" : "400px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            )}
          </div>
          <div
            className={Ho.rightthumb}
            onClick={
              openYoutubeModal
                ? null
                : () => {
                    setCurrentVideoId(2);
                    setOpenYoutubeModal(true);
                  }
            }
            style={{ zIndex: openYoutubeModal ? -1 : 1000 }}
          >
            <img
              src={thumbnails[2]}
              alt="thumbone"
              style={{
                width: isMobile ? "20vw" : "220px",
                height: isMobile ? "160px" : "340px",
                borderRadius: "10px",
                opacity: openYoutubeModal ? "0.1" : "1",
                zIndex: -1000,
              }}
            />
          </div>
        </div>
        <Link to="/howtoresources">
          <button className={Ho.tellmemore}>View full Playlist</button>
        </Link>
      </div>
      <div className={Ho.sectionnine}>
        <div className={Ho.sectioneightcontentholder}>
          <div className={Ho.askusholder}>
            <h3 className={Ho.gradienthead}>Ask us anything</h3>
            <form
              action={submiturl}
              method="post"
              className={Ho.formitself}
              target="_blank"
              noValidate
            >
              <div className={Ho.formholder}>
                <input
                  className={Ho.forminputfield}
                  type="text"
                  placeholder="Shoot us with your hardest question"
                  name="SHOOTUS"
                  onChange={(e) => {
                    setQuestionErr("");
                    setQuestion(e.target.value);
                    if (
                      e.target.value === "" ||
                      e.target.value === null ||
                      e.target.value.trim() === ""
                    ) {
                      setQuestionErr("This field cannot be empty");
                      setIsFormValid(false);
                      return;
                    }
                    setIsFormValid(true);
                  }}
                />
                <h3 className={Ho.error}>{questionErr}</h3>
                <input
                  className={Ho.forminputfield}
                  type="text"
                  placeholder="Enter your email"
                  name="EMAIL"
                  onChange={(e) => {
                    setEmailErr("");
                    setEmail(e.target.value);
                    if (
                      e.target.value === "" ||
                      e.target.value === null ||
                      e.target.value.trim() === ""
                    ) {
                      setEmailErr("Email cannot be empty");
                      setIsFormValid(false);
                      return;
                    }
                    if (
                      !e.target.value.match(
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
                      )
                    ) {
                      setEmailErr("Incorrect email format");
                      setIsFormValid(false);
                      return;
                    }
                    setIsFormValid(true);
                  }}
                />
              </div>
              <h3 className={Ho.error}>{emailErr}</h3>
              <div className={Ho.submitbuttonholder}>
                <button
                  className={
                    isFormValid && email !== "" && question !== ""
                      ? Ho.formsubmit
                      : Ho.formsubmitDisabled
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className={Ho.rightfaqholder}>
            <h3 className={Ho.faqheading}>Frequently Asked Quesitons:</h3>
            <div className={Ho.accordionsholder}>
              <Accordion
                className={Ho.accordion}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  className={Ho.accordionsummary}
                  expandIcon={<ExpandMoreIcon className={Ho.accordionicon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={Ho.accordiontype}>
                    How smart is the Sense 1 camera?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={Ho.accordiondetails}>
                  <Typography className={Ho.accordiontype}>
                    The Sense 1 is an AI-powered security camera that enables
                    safety and convenience. You can easily detect intrusion,
                    package thefts, and a lot more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={Ho.accordion}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  className={Ho.accordionsummary}
                  expandIcon={<ExpandMoreIcon className={Ho.accordionicon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={Ho.accordiontype}>
                    How is Sense 1 different from other cameras?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={Ho.accordiondetails}>
                  <Typography className={Ho.accordiontype}>
                    The Sense 1 is a wireless and AI fueled camera which works
                    indoors & outdoors in all weather conditions.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={Ho.accordion}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={Ho.accordionicon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={Ho.accordiontype}>
                    How can I use Sense1?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Ho.accordiontype}>
                    You can use the Sense 1 to keep an eye on your parents, kids
                    and pets. Not only this but it can also be used to track
                    deliveries and house-help.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                className={Ho.accordion}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={Ho.accordionicon} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={Ho.accordiontype}>
                    Where can I buy the Sense 1?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={Ho.accordiontype}>
                    Order now{" "}
                    <a
                      href="https://shop.avairsense.com/products/sense-1"
                      className={Ho.link}
                    >
                      here
                    </a>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className={Ho.sectionten}>
        <h3 className={Ho.gradientheading}>Backed by</h3>
        <h4 className={Ho.sectionheading}>The World's Best</h4>
        <div className={Ho.logoholder}>
          <img
            className={Ho.backedYlogo}
            alt="backedbylogo"
            src={YCombinatorLogo}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeTwo;
