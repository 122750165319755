// Dependencies imports
import React from "react";

// Local imports

// Style imports
import Gr from "./GradientBar.module.scss";

const GradientBar = () => {
  return <div className={Gr.mainbar}></div>;
};

export default GradientBar;
