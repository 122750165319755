// Dependency imports
import React from "react";
import { Helmet } from "react-helmet";

// Local imports
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import FeaturesBanner from "../../assets/images/features/featuresbanner.webp";
import BatteryIcon from "../../assets/images/features/batteryicon.webp";
import GradientBar from "../../components/gradientline/GradientBar";
import TwoWayAudio from "../../assets/images/features/2-way-audio.webp";
import CloudStorage from "../../assets/images/features/cloudandlocalstorage.webp";
import MagneticMount from "../../assets/images/features/magneticsnapmount.webp";
import PerimeterZone from "../../assets/images/features/perimeterzone.webp";
import PirSensor from "../../assets/images/features/pirsensor.webp";
import AllWeather from "../../assets/images/features/allweather.gif";
import CompactAndElegant from "../../assets/images/features/compactandelegant.webp";
import SunIcon from "../../assets/images/features/sun.webp";
import RainIcon from "../../assets/images/features/rain.webp";
import SnowIcon from "../../assets/images/features/snow.webp";
import MobileFeatureBanner from "../../assets/images/features/mobilefeaturebanner.webp";
import MadeInIndia from "../../assets/images/features/madeinindia.webp";

// Style imports
import Fe from "./Features.module.scss";

const Features = () => {
  return (
    <div className={Fe.mainholder}>
      <Helmet>
        <title>
          Sense 1 Features | Smartest Features for the Smartest Camera Ever |
          AvairSense
        </title>
        <meta
          name="description"
          content="Discover the advanced features of the AvairSense Sense 1 camera. Sense 1 comes with human, pet, and vehicle detection, two-way audio, cloud storage, and a built-in battery. With privacy modes, you can switch off the camera when not required."
        />
      </Helmet>
      <Header />
      <div className={Fe.sectionone}>
        <h1 className={Fe.bannerheading}>
          <span className={Fe.gradienttext}>Smartest Features </span>for the
          <br />
          Smartest Camera ever.
        </h1>
        <img
          className={Fe.bannerimage}
          alt="bannerimage"
          src={FeaturesBanner}
        />{" "}
        <img
          className={Fe.mobilebannerimage}
          alt="mobilebannerimage"
          src={MobileFeatureBanner}
        />
      </div>
      <div className={Fe.featuresholder}>
        <div className={Fe.featuresection}>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={BatteryIcon}
          />
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h3 className={Fe.featuresmallheading}>A battery that</h3>
            <h4 className={Fe.featuremainheading}>
              lasts for
              <br /> months
            </h4>
            <p className={Fe.featureexplain}>
              With long lasting battery life, protect your home even when the
              lights go out.
            </p>
            <img
              className={Fe.madeinindiaimage}
              src={MadeInIndia}
              alt="madeininda"
            />
          </div>
        </div>
        <div className={Fe.mobilefeaturesection}>
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              Compact &
              <br /> Elegant
            </h4>
            <p className={Fe.featureexplain}>
              Home monitoring without compromising your privacy.
            </p>
          </div>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={CompactAndElegant}
          />
        </div>
        <div className={Fe.featuresection}>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={AllWeather}
          />
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              All Weather &
              <br /> Wireless
            </h4>
            <p className={Fe.featureexplain}>
              Keep track of everything that's happening around you in all
              weather conditions.
            </p>
            <div className={Fe.weatherresistanceicons}>
              <img className={Fe.weathericon} alt="weathericon" src={SunIcon} />
              <img
                className={Fe.weathericon}
                alt="weathericon"
                src={RainIcon}
              />
              <img
                className={Fe.weathericon}
                alt="weathericon"
                src={SnowIcon}
              />
            </div>
          </div>
        </div>
        <div className={Fe.mobilefeaturesection}>
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              Perimeter
              <br /> Zones
            </h4>
            <p className={Fe.featureexplain}>
              Mark your perimeter for specific needs and our AI will take care
              of the rest.
            </p>
          </div>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={PerimeterZone}
          />
        </div>
        <div className={Fe.featuresection}>
          <img
            className={Fe.featuremobileimage}
            alt="featureimage"
            src={TwoWayAudio}
          />
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              2 way voice
              <br /> with playback <br />
              in FHD
            </h4>
            <p className={Fe.featureexplain}>
              Talk seamlessly to whoever's at your door in FHD playback with
              automatic night vision.
            </p>
          </div>
        </div>
        <div className={Fe.mobilefeaturesection}>
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              PIR sensor for
              <br />
              motion <br />
              detection
            </h4>
            <p className={Fe.featureexplain}>
              Detect motion and sends instant notifications so that you are
              always Avair.
            </p>
            <img
              className={Fe.madeinindiaimage}
              src={MadeInIndia}
              alt="madeininda"
            />
          </div>
          <img className={Fe.featureimage} alt="featureimage" src={PirSensor} />
        </div>
        {/* <div className={Fe.featuresection}>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={AlexaAndGoogle}
          />
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              Foundation
              <br />
              for a smarter <br />
              home with AI
            </h4>
            <p className={Fe.featureexplain}>
              Cutting-edge technology for Human/Pet/Vehicle detection, and
              facial recognition.
            </p>
            <div className={Fe.alexaholder}>
              <img
                src={AlexaIcon}
                alt="assistanceicon"
                className={Fe.assistanceimages}
              />
              <img
                src={GoogleAssitance}
                alt="assistanceicon"
                className={Fe.assistanceimages}
              />
            </div>
          </div>
        </div> */}
        <div className={Fe.featuresection}>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={MagneticMount}
          />
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              Magnetic
              <br />
              Snap Mount
            </h4>
            <p className={Fe.featureexplain}>
              Magnetic mount that snaps to your camera and makes the device
              installation seamless.
            </p>
          </div>
        </div>
        <div className={Fe.mobilefeaturesection}>
          <div className={Fe.featurecontentholder}>
            <GradientBar />
            <h4 className={Fe.featuremainheading}>
              Cloud & Local
              <br />
              Storage
            </h4>
            <p className={Fe.featureexplain}>
              Keep your playbacks and events saved at your fingertips.
            </p>
          </div>
          <img
            className={Fe.featureimage}
            alt="featureimage"
            src={CloudStorage}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Features;
