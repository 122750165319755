// Dependencies imports
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import YouTube from "react-youtube";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import moment from "moment";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";

// Local imports
import Header from "../../components/header/Header";

import { resourceState } from "../../recoil/atoms/resourcestate";
import Footer from "../../components/footer/Footer";
import ResCreativeImage from "../../assets/images/resources/resourcescreative.webp";
import HowToVideosImage from "../../assets/images/resources/howtovideos.webp";
import ArrowDownImage from "../../assets/images/resources/arrowdown.webp";

// Style imports
import Res from "./Resources.module.scss";

const accordiondata = [
  {
    question: "What is the battery backup of Sense1?",
    answer:
      "Sense1 battery lasts for over a month and battery life varies by usage.",
    pannel: "pannel1",
  },
  {
    question: "Does Sense1 require an internet connection?",
    answer: "Yes, it requires a stable internet connection.",
    pannel: "pannel2",
  },
  {
    question: "What is the storage capacity of a Sense1?",
    answer:
      "Sense1 has unlimited storage on cloud and locally it supports 128Gb on Sd card.",
    pannel: "pannel3",
  },
  {
    question:
      "Can I view live footage from my smart security camera on my phone?",
    answer:
      "Yes, with Sense1 security camera, you can view the live footage, from anywhere around the world.",
    pannel: "pannel4",
  },
  {
    question: "Do I need to buy a subscription plan for Sense 1?",
    answer:
      "We have 2 subscription plans, Sense Nova and Sense Arora, Sense Nova is a complimentary plan provided for 1 year with every purchase.",
    pannel: "pannel5",
  },
  {
    question: "Does Sense1 record continuously?",
    answer: "Sense1 records whenever there is a motion detected.",
    pannel: "pannel6",
  },
  {
    question:
      "How do I access the footage recorded by my smart security camera?",
    answer: "We have a playback button on our app, to access recordings.",
    pannel: "pannel7",
  },
  {
    question: "How do I troubleshoot any issues with Sense1?",
    answer:
      "In most of the cases reseting and onboarding the camera will work and we also have an in app support system to help you out.",
    pannel: "pannel8",
  },
  {
    question: "Can Sense1 be used outdoors?",
    answer: "Yes, Sense1 can be used both outdoors and indoors.",
    pannel: "pannel9",
  },
];

const Resources = () => {
  const [expanded, setExpanded] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [loading, setLoading] = useState(false);
  const [resource, setResource] = useRecoilState(resourceState);

  const opts = {
    height: isMobile ? "240" : "500",
    width: isMobile ? "380" : "900",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      VideoPlaybackQuality: "1080p",
    },
  };

  useEffect(() => {
    getAllResources();

    //eslint-disable-next-line
  }, []);

  const getAllResources = () => {
    setLoading(true);

    var config = {
      method: "GET",
      url: `${process.env.REACT_APP_RESOURCE_ENDPOINT}/api/resources?populate=*`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios(config)
      .then(function (result) {
        setResourceData(result.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={Res.maincontainer}>
      <Header />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openModal}
        onClick={() => {
          setOpenModal(false);
          setVideoId("");
        }}
      >
        <YouTube
          videoId={videoId}
          opts={opts}
          onEnd={() => setOpenModal(false)}
        />
      </Backdrop>
      <div className={Res.mainholder}>
        <div className={Res.sectionfirst}>
          <div className={Res.leftsection}>
            <h1 className={Res.headingfirst}>
              Your one stop for all thing{" "}
              <span className={Res.gradienttext}>Sense.</span>
            </h1>
            <img
              className={Res.howtoimage}
              src={HowToVideosImage}
              alt="Howtoimage | Sense 1"
            />
          </div>
          <div className={Res.rightsection}>
            <img
              className={Res.rightimage}
              alt="sense resources"
              src={ResCreativeImage}
            />
          </div>
          <img className={Res.arrowdown} src={ArrowDownImage} alt="arrowdown" />
        </div>
        <div className={Res.sectiontwo}>
          <h2 className={Res.sectionhead}>Resources</h2>
          <div className={Res.videoresholder}>
            {resourceData.map((val, index) => {
              return (
                <div className={Res.resholder}>
                  <img
                    className={Res.restthumbnail}
                    src={val.attributes.thumbnail.data.attributes.url}
                    alt="videothumbnail"
                    onClick={() => {
                      setVideoId(val.attributes.YoutubeLink);
                      setOpenModal(true);
                    }}
                  />
                  <h3 className={Res.reshead}>{val.attributes.Heading}</h3>
                  <h4 className={Res.resdate}>
                    {moment(val.attributes.createdAt).format("MMM Do YY")}
                  </h4>
                  <p className={Res.resdescription}>
                    {val.attributes.ResourceContent.substring(0, 100)}..
                    <Link
                      to={`/resource/${val.id}:${val.attributes.Heading}`}
                      onClick={() => setResource(val.id.toString())}
                      className={Res.readmorelink}
                    >
                      Read more
                    </Link>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={Res.sectionthree}>
          <h2 className={Res.sectionhead}>FAQ</h2>
          <div className={Res.faqholder}>
            {accordiondata.map((value, index) => {
              return (
                <Accordion
                  className={Res.accordion}
                  expanded={expanded === value.pannel}
                  onChange={handleChange(value.pannel)}
                >
                  <AccordionSummary
                    className={Res.accordionsummary}
                    expandIcon={
                      <ExpandMoreIcon className={Res.accordionicon} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={Res.accordiontype}>
                      {value.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={Res.accordiondetails}>
                    <Typography className={Res.accordiontype}>
                      {value.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
        <div className={Res.sectionfour}>
          <div className={Res.helpleft}>
            <h3 className={Res.sectionhead}>
              Didn't find what you were looking for?
            </h3>
            <h4 className={Res.explain}>
              Don't worry, we're happy to help. Ask us anything
            </h4>
          </div>
          <div className={Res.helpright}>
            <div className={Res.inputholder}>
              <label className={Res.label}>What's your name?</label>
              <input className={Res.input} type="text" />
            </div>
            <div className={Res.inputholder}>
              <label className={Res.label}>Your email</label>
              <input className={Res.input} type="text" />
            </div>
            <div className={Res.inputholder}>
              <label className={Res.label}>What can you help me with?</label>
              <input className={Res.input} type="text" />
            </div>
            <button className={Res.buttonsubmit}>Submit</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Resources;
