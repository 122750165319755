import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Local imports
import { offeredCustomer } from "../../recoil/atoms/offeredCustomer";
import CamSense from "../../assets/images/home/camsense.webp";

// Style imports
import Sp from "./OffersModal.module.scss";

const OffersModal = (props) => {
  const { handleCloseOfferModal } = props;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [locality, setLocality] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [localityErr, setLocalityErr] = useState("");
  const [customerOffer, setCustomerOffer] = useRecoilState(offeredCustomer);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    let isFormValid = true;
    setNameErr("");
    setEmailErr("");
    setPhoneErr("");
    setLocalityErr("");

    if (name === "" || name === null || name.trim() === "") {
      isFormValid = false;
      setNameErr("Name cannot be empty");
      return;
    }
    if (email === "" || email === null || email.trim() === "") {
      setEmailErr("Email cannot be empty");
      isFormValid = false;
      return;
    }
    if (!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      setEmailErr("Incorrect email format");
      isFormValid = false;
      return;
    }
    if (
      phoneNumber === "" ||
      phoneNumber === null ||
      phoneNumber.trim() === ""
    ) {
      setPhoneErr("Phone number cannot be empty");
      isFormValid = false;
      return;
    }
    if (phoneNumber.length !== 10) {
      setPhoneErr("Phone number should contain 10 digits");
      isFormValid = false;
      return;
    }
    if (/^\d+$/.test(phoneNumber) === false) {
      setPhoneErr("Phone number should contain only numbers");
      isFormValid = false;
      return;
    }
    if (locality === "" || locality === null || locality.trim() === "") {
      setLocalityErr("Please select your apartment");
      isFormValid = false;
      return;
    }
    if (isFormValid) {
      getCouponCode();
    }
  };

  //API to issue kits
  const getCouponCode = () => {
    setLoading(true);
    let data = {
      name: name.toString(),
      contact: phoneNumber.toString(),
      email: email.toString(),
      locality: locality.toString(),
    };

    var config = {
      method: "POST",
      url: `https://api.dev.avairsense.com/coupon/add/drip`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (result) {
        setLoading(false);
        let datatoset = {
          name: name,
          contact: phoneNumber,
          email: email,
          apartment: locality,
          coupon: "SENSE499",
        };
        setCustomerOffer(datatoset);
        setShowCard(true);
        // navigate("/couponcode");
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  return (
    <div className={showCard ? Sp.mainmodalflipped : Sp.mainmodal}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <div className={Sp.topclosebar}>
        <Close onClick={handleCloseOfferModal} className={Sp.closeicon} />
      </div> */}
      {showCard ? (
        <div className={Sp.contentholder}>
          <h1 className={Sp.namehead}>Hi {name}</h1>
          <h3 className={Sp.intro}>
            Thank you for showing interest. Here's a special discount code for
            you to use during checkout.✨
          </h3>

          <div className={Sp.cardholder}>
            <img className={Sp.senseimage} alt="sense" src={CamSense} />
            <h1 className={Sp.codestyle}>SENSE499</h1>
          </div>
          <h3 className={Sp.intro}>
            MRP- <span className={Sp.strikedamount}>₹ 14,999</span> ₹6998 - ₹499
            = <span className={Sp.gradienttext}>₹6499 effective price</span>
          </h3>
          <CopyToClipboard text={customerOffer.coupon}>
            <a
              onClick={() => {
                navigator.clipboard.writeText();
              }}
              href="https://shop.avairsense.com"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "none",
              }}
            >
              <button className={Sp.copybutton}>Copy & Go to Shop</button>
            </a>
          </CopyToClipboard>
        </div>
      ) : (
        <div className={Sp.modalcontainer}>
          <h1 className={Sp.hello}>Special Coupon Inside</h1>
          <h4 className={Sp.intro}>
            Fill this form to Join the Sense Club and Get Notified about all our
            products. There's a special coupon code inside!
          </h4>
          <div className={Sp.rightholder}>
            <input
              type="text"
              value={name}
              name="NAME"
              placeholder="Full Name"
              className={Sp.forminput}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <p className={Sp.formerror}>{nameErr}</p>
            <input
              type="email"
              name="EMAIL"
              value={email}
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={Sp.forminput}
            />
            <p className={Sp.formerror}>{emailErr}</p>
            <input
              type="tel"
              value={phoneNumber}
              name="PHONE"
              placeholder="WhatsApp Number"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className={Sp.forminput}
            />
            <p className={Sp.formerror}>{phoneErr}</p>
            <input
              type="text"
              value={locality}
              name="LOCALITY"
              placeholder="City"
              onChange={(e) => setLocality(e.target.value)}
              className={Sp.forminput}
            />
            <p className={Sp.formerror}>{localityErr}</p>
            <button className={Sp.submitbutton} onClick={handleSubmit}>
              Send Coupon
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OffersModal;
